<template>
  <div v-if="loaded">
    <div class="columns is-gapless">
      <div class="column">
        <div class="padding-x-4 padding-x-5-tablet">
          <div class="section-title">
            User Info
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <b-field class="required" label="First Name" :type="{'is-danger': errors.has('firstName')}" :message="errors.first('firstName')">
                <b-input v-model="user.firstName" v-validate="'required'" name="firstName" data-vv-as="first name" />
              </b-field>
            </div>
            <div class="column">
              <b-field class="required" label="Last Name" :type="{'is-danger': errors.has('lastName')}" :message="errors.first('lastName')">
                <b-input v-model="user.lastName" v-validate="'required'" name="lastName" data-vv-as="last name" />
              </b-field>
            </div>
          </div>
          <b-field class="required" label="Username (email)" :type="{'is-danger': errors.has('username')}" :message="errors.first('username')">
            <b-input ref="username" v-model="user.username" v-validate="'required|email|validateEmail:user.username'" type="email" autocomplete="off" name="username" data-vv-as="username" />
          </b-field>
          <b-field class="required" label="Password">
            <b-input
              v-model="user.password"
              type="password"
              autocomplete="new-password"
              password-reveal />
          </b-field>
          <b-switch v-show="id != 'new'" v-model="user.u2fEnabled" true-value="1" false-value="0">
            Two Factor Authentication
          </b-switch>
          <div v-if="user.u2fEnabled==1" class="column is-narrow">
            <b-field v-if="!user.yubikeySerial" label="Secret Key">
              <b-input ref="dongle" v-model="user.dongle" placeholder="Touch the dongle button" type="password" @keyup.enter.native="registerOTP()" />
            </b-field>
            <b-field v-if="user.yubikeySerial" label="Serial">
              {{ user.yubikeySerial }}
            </b-field>
            <!-- Insert and tap your dongle when it starts blinking to activate. -->
            <b-notification :closable="false">
              <small v-html="yubikeyMessage" />
            </b-notification>
          </div>
          <!-- <div class="column is-narrow" v-if="user.u2fEnabled==1">
            <b-button :disabled="busy" type="is-success" size="is-medium" @click="register()">
              Start Registration
            </b-button>
            <b-notification :closable="false">
              <small>{{yubikeyMessage}}</small>
            </b-notification>
          </div> -->
          <b-message type="is-danger" title="Error" :active.sync="isYubikeyMessageError" aria-close-label="Close message">
            {{ yubikeyMessageError }}
          </b-message>
        </div>
      </div>
      <div class="column">
        <div class="padding-xy-4 padding-y-0-tablet">
          <div class="card has-shadow-30 padding-xy-4">
            <div class="columns is-mobile is-vcentered">
              <div class="column">
                <div class="section-title is-marginless">
                  Access
                </div>
              </div>
              <div class="column is-narrow">
                <b-field>
                  <b-select v-model="user.type" placeholder="User Type">
                    <option value="licensee">
                      Licensee
                    </option>
                    <option value="jfe">
                      JFE Office
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div v-if="user.type === 'licensee'">
              <b-field class="required" label="Licensee Office" :type="{'is-danger': errors.has('licenseeId')}" :message="errors.first('licenseeId')">
                <autocomplete-single
                  v-model="user.licenseeId"
                  v-validate="'required'"
                  class="field no-icon wrap-item"
                  placeholder="Licensee"
                  icon="id-badge"
                  :open-on-focus="true"
                  :items="licensees"
                  text-field="name"
                  value-field="id"
                  name="licenseeId"
                  data-vv-as="licensee office"
                  expanded />
              </b-field>
              <b-field label="Divisions">
                <b-taginput
                  v-model="userLicenseeDivisions"
                  field="name"
                  :data="userLicensee ? userLicensee.divisions.filter(d => !user.licenseeDivisionIds.includes(d.id)) : []"
                  :placeholder="placeholderDivisions"
                  autocomplete
                  open-on-focus
                  class="fullcontrol"
                  attached>
                  <template slot-scope="props">
                    {{ props.option.name }}
                  </template>
                </b-taginput>
              </b-field>
              <b-field class="field padding-y-1 is-marginless">
                <b-checkbox v-model="user.hasLicenseeTechnicalDataPermission" true-value="1" false-value="0">
                  Technical Documents
                </b-checkbox>
              </b-field>
              <b-field class="field padding-y-1 is-marginless">
                <b-checkbox v-model="user.hasLicenseeInspectionSheetPermission" true-value="1" false-value="0">
                  Inspection Sheets
                </b-checkbox>
              </b-field>
              <!-- <b-field class="field padding-y-1 is-marginless">
                <b-checkbox v-model="user.hasLicenseeBlankingDimensionsPermission" true-value="1" false-value="0">
                  Blanking Dimensions
                </b-checkbox>
              </b-field> -->
              <b-field class="field padding-y-1 is-marginless">
                <b-checkbox v-model="user.hasLicenseeRoyaltyTrackerPermission" true-value="1" false-value="0">
                  Royalty Tracker
                </b-checkbox>
              </b-field>
              <b-field v-if="user.hasLicenseeRoyaltyTrackerPermission" class="field padding-y-1 is-marginless">
                <b-checkbox class="is-invisible" />
                <b-checkbox v-model="user.receiveInvoiceNotification" class="is-marginless" true-value="1" false-value="0">
                  Invoice Notifications
                </b-checkbox>
              </b-field>
            </div>
            <div v-if="user.type === 'jfe'">
              <div />
              <b-field class="required" label="JFE Office" :type="{'is-danger': errors.has('JFEofficeId')}" :message="errors.first('JFEofficeId')">
                <b-select v-model="user.officeId" v-validate="'required'" name="JFEofficeId" data-vv-as="JFE Office" expanded>
                  <option v-for="office in orderBy(offices, 'name')" :key="office.id" :value="office.id">
                    {{ office.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field class="field padding-y-1 is-marginless">
                <b-checkbox v-model="user.hasJfeAdminPermission" native-value="admin" true-value="1" false-value="0">
                  Admin
                </b-checkbox>
              </b-field>
              <b-field class="field padding-y-1 is-marginless">
                <b-checkbox v-model="user.hasJfeRoyaltyTrackerPermission" native-value="royalty" true-value="1" false-value="0">
                  Royalty Tracker
                </b-checkbox>
              </b-field>
              <b-field class="field padding-y-1 is-marginless">
                <b-checkbox v-model="user.hasJfeInspectionSheetPermission" native-value="inspection" true-value="1" false-value="0">
                  Inspection Sheets
                </b-checkbox>
              </b-field>
              <b-field class="field padding-y-1 is-marginless">
                <b-checkbox v-model="user.hasJfeDataManagerPermission" native-value="data" true-value="1" false-value="0">
                  Data Sheet Editor
                </b-checkbox>
              </b-field>
              <!-- <b-field class="field padding-y-1 is-marginless">
                <b-checkbox v-model="user.hasJfeBlankingDimensionsPermission" native-value="blanking" true-value="1" false-value="0">
                  Blanking Dimensions
                </b-checkbox>
              </b-field> -->
              <b-field class="field padding-y-1 is-marginless">
                <b-checkbox disabled :value="true">
                  User
                </b-checkbox>
              </b-field>
            </div>
            <hr class="margin-y-3">
            <b-switch v-model="user.enabled" true-value="1" false-value="0">
              Active
            </b-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="padding-y-6" />
    <manage-buttons @cancel="cancel" @save="save" />
  </div>
</template>

<script>

import Vue2Filters from 'vue2-filters'
import UsersApi from '@/apis/UsersApi'
import ManageButtons from '@/components/navigation/ManageButtons'
const { Yubico } = require('yubico-node')

const allDivisionsObject = {
  id: 0,
  licenseeId: 0,
  name: 'All divisions'
}

export default {
  components: {
    ManageButtons
  },
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      user: {
        username: null,
        password: null,
        firstName: null,
        lastName: null,
        dongle: null,
        type: 'licensee',
        officeId: null,
        licenseeId: null,
        licenseeDivisionIds: [],
        hasJfeAdminPermission: false,
        hasJfeDataManagerPermission: false,
        hasJfeRoyaltyTrackerPermission: false,
        hasJfeInspectionSheetPermission: false,
        hasJfeBlankingDimensionsPermission: false,
        hasLicenseeRoyaltyTrackerPermission: 1,
        hasLicenseeTechnicalDataPermission: false,
        hasLicenseeInspectionSheetPermission: false,
        hasLicenseeBlankingDimensionsPermission: false,
        enabled: 1
      },
      loaded: false,
      placeholderDivisions: '',
      yubikeyMessage: null,
      yubikeyMessageError: null,
      busy: false,
      overrideRegister: 0
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    id () {
      return this.$route.params.id
    },
    isYubikeyMessageError: {
      get () {
        if (this.yubikeyMessageError) {
          return true
        } else {
          return false
        }
      },
      set (val) {
        return val
      }
    },
    offices () {
      return this.$store.state.offices
    },
    licensees () {
      return this.orderBy(this.$store.state.licensees, 'name')
    },
    userLicensee: {
      get () {
        return this.licensees.find(licensee => licensee.id === this.user.licenseeId)
      },
      set (val) {
        this.user.licenseeId = val.id
      }
    },
    userLicenseeDivisions: {
      get () {
        return this.userLicensee ? this.userLicensee.divisions.filter(d => this.user.licenseeDivisionIds.includes(d.id)) : []
      },
      set (val) {
        this.user.licenseeDivisionIds = val.map(v => v.id)
      }
    },
    userOfficeId: {
      get () {
        return this.user.officeIds.length ? this.user.officeIds[0] : null
      },
      set (val) {
        this.user.officeIds = [val]
      }
    }
  },
  watch: {
    'userLicenseeDivisions.length' (length) {
      if (length === 0) {
        // Resetting values
        this.placeholderDivisions = allDivisionsObject.name
        this.user.licenseeDivisionIds = []
      } else {
        this.placeholderDivisions = null
      }
    }
  },
  async mounted () {
    this.$store.commit('setTitle', null)

    await this.$store.dispatch('refreshOffices')
    await this.$store.dispatch('refreshLicensees')

    this.placeholderDivisions = allDivisionsObject.name

    if (this.id === 'new') {
      this.$store.commit('setTitle', 'New User')
    } else {
      this.user = await UsersApi.getUserById(this.id)
      if (this.user.yubikeySerial) {
        this.yubikeyMessage = ''
      } else {
        this.yubikeyMessage = '<ul><li>Insert your dongle into a USB port</li><li>Click in the secret key field, and touch the dongle button</li></ul>'
      }
      this.$store.commit('setTitle', 'User: ' + (this.user.firstName + ' ' + this.user.lastName).trim())
    }

    this.$validator.extend('validateEmail', {
      getMessage: field => 'Email is already in use!',
      validate: async value => {
        try {
          var userVerified = await UsersApi.getUserByUsername(value)

          if (String(this.id) === String(userVerified.id)) { // system found the same user
            return true
          } else {
            return false
          }
        } catch (err) {
          return true
        }
      }
    })

    this.loaded = true
  },
  methods: {
    async save (redirect = true) {
      if (await this.$validator.validateAll()) {
        if (this.user.type === 'jfe') {
          this.user.licenseeDivisionIds = []
          this.user.licenseeId = null
          this.user.hasLicenseeTechnicalDataPermission = false
          this.user.hasLicenseeRoyaltyTrackerPermission = false
          this.user.hasLicenseeInspectionSheetPermission = false
          this.user.hasLicenseeBlankingDimensionsPermission = false
          this.user.receiveInvoiceNotification = false
        } else {
          this.user.officeId = null
          this.user.hasJfeAdminPermission = false
          this.user.hasJfeDataManagerPermission = false
          this.user.hasJfeRoyaltyTrackerPermission = false
          this.user.hasJfeInspectionSheetPermission = false
          this.user.hasJfeBlankingDimensionsPermission = false
        }

        if (this.user.u2fEnabled === '0') {
          this.user.u2fPublicKey = null
          this.user.u2fKeyHandle = null
          this.user.u2fKeyRegistration = null
          this.user.yubikeySerial = null
          this.user.yubikeyIdentity = null
        }

        if (this.id === 'new') {
          this.user = await UsersApi.createUser(this.user)
        } else {
          this.user = await UsersApi.updateUser(this.id, this.user)
        }

        if (redirect) {
          this.$router.push({ name: 'manage_users' })
        }
      }
    },
    async registerOTP () {
      this.yubikeyMessageError = null
      const loadingComponent = this.$buefy.loading.open({ container: null })
      const yubic = new Yubico({
        clientId: process.env.VUE_APP_YUBICO_CLIENT_ID,
        secret: process.env.VUE_APP_YUBICO_SECRET,
        sl: 100,
        timeout: 30
      })

      try {
        var res = await yubic.verify(this.user.dongle)
        // attempt to verify the key
        if (res.getStatus() === 'OK') {
          try {
            // Check Serial Yubikey used
            var yubikeyUser = await UsersApi.getUserByYubikeySerial(res.getSerialNumber())
            // var maskid = yubikeyUser.username.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c)
            this.yubikeyMessageError = 'This dongle is already assigned to ' + yubikeyUser.firstName + ' ' + yubikeyUser.lastName.charAt(0)
            this.user.dongle = null
            this.$refs.dongle.$el.focus()
          } catch (err) {
            this.yubikeyMessage = 'The hardware key has been registered'
            this.user.yubikeyIdentity = res.getPublicId()
            this.user.yubikeySerial = res.getSerialNumber()
            this.user.u2fKeyRegistration = JSON.stringify(res)
            this.save(false)
          }
        } else {
          if (this.user.dongle) {
            this.yubikeyMessageError = 'Dongle validation expired. Please try again.'
          } else {
            this.yubikeyMessageError = 'Insert and tap your dongle'
          }
          this.user.dongle = null
          this.$refs.dongle.$el.focus()
        }
      } catch (err) {
        this.yubikeyMessageError = err
        this.user.dongle = null
        this.$refs.dongle.$el.focus()
        console.error(err)
      } finally {
        loadingComponent.close()
      }
    },
    async cancel () {
      this.$router.push({ name: 'manage_users' })
    }
  }
}

</script>
